import React from "react";
import ReactDOM from "react-dom";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function Documentation() {
  return(
  <>
    <Navbar transparent />
     <main>
      <section className="pt-20 pb-48">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-center mb-24">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold">
                Documentation
              </h2>
              <p className="text-lg leading-relaxed m-4 text-gray-600">
                Mfactor is designed to be integrated with any gateway that can be cofigured in app settings after user has installed the app.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">   
              <div className="rounded-full max-w-full mx-auto text-center">
                <i className="fas fa-arrow-alt-circle-down" style={{color:"#D7AE57", fontSize:"4rem"}}></i>
              </div>            
                
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold uppercase">
                    Installation
                  </h5>
                  <p className="mt-1 text-sm text-gray-500 font-semibold">
                    The app is now available on playstore through this link &nbsp;
                    <a href="https://play.google.com/store/apps/details?id=com.wd.mfactor" target="_blank" style={{textDecoration:"underline"}}>Download</a>
                  </p>

                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">
              <div className="rounded-full max-w-full mx-auto text-center">
                <i className="fas fa-key" style={{color:"#D7AE57", fontSize:"4rem"}}></i>
              </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold uppercase">
                    Configuration
                  </h5>
                  <p className="mt-1 text-sm text-gray-500 font-semibold">
                    Configure gateway of preference, you can choose to use the defaulty gateway.
                  </p>

                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">
              <div className="rounded-full max-w-full mx-auto text-center">
                <i className="fas fa-user" style={{color:"#D7AE57", fontSize:"4rem"}}></i>
              </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold uppercase">
                    User Key
                  </h5>
                  <p className="mt-1 text-sm text-gray-500 font-semibold">
                    Supply user and key identified by the gateway configured
                  </p>

                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">
              <div className="rounded-full max-w-full mx-auto text-center">
                <i className="fa fa-fingerprint" style={{color:"#D7AE57", fontSize:"4rem"}}></i>
              </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold uppercase">
                    Authentication
                  </h5>
                  <p className="mt-1 text-sm text-gray-500 font-semibold">
                    You will be able to authenticate all access requests initiated.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </>
  );

}