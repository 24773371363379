import React from "react";
import ReactDOM from "react-dom";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function Pricing() {
  return(
  <>
    <Navbar transparent />
     <main>
      <section className="pt-20 pb-48">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-center mb-24">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold">
              Pricing
              </h2>
              <p className="text-lg leading-relaxed m-4 text-gray-600">
                Flexible to choose the package that suits your need
              </p>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">   
              <div className="rounded-full max-w-full mx-auto text-center">
                <i className="fas fa-fingerprint" style={{color:"#777777", fontSize:"4rem"}}></i>
              </div>            
                
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold uppercase">
                    Free
                  </h5>
                  <p className="mt-1 text-sm text-gray-500 font-semibold">
                    $0/user/month <br/>
                    For all users of MfukoPesa and its partner solutions or if you have your own gateway. 

                  </p>

                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">
              <div className="rounded-full max-w-full mx-auto text-center">
                <i className="fas fa-fingerprint" style={{color:"#C0C0C0", fontSize:"4rem"}}></i>
              </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold uppercase">
                    Silver
                  </h5>
                  <p className="mt-1 text-sm text-gray-500 font-semibold">
                    $1.5/user/month <br/>
                    Cloud solution with our available gateway, users above 200.
                  </p>

                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">
              <div className="rounded-full max-w-full mx-auto text-center">
                <i className="fas fa-fingerprint" style={{color:"#D7AE57", fontSize:"4rem"}}></i>
              </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold uppercase">
                    Gold
                  </h5>
                  <p className="mt-1 text-sm text-gray-500 font-semibold">
                  $2.5/user/month <br/>
                    Cloud solution with our available gateway, users below 200.
                  </p>

                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">
              <div className="rounded-full max-w-full mx-auto text-center">
                <i className="fa fa-fingerprint" style={{color:"#E5E4E2", fontSize:"4rem"}}></i>
              </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold uppercase">
                    Platinum
                  </h5>
                  <p className="mt-1 text-sm text-gray-500 font-semibold">
                  $3/user/month <br/>
                    On premise option available for any number of users.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </>
  );

}