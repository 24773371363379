import React from "react";
import ReactDOM from "react-dom";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function Privacy() {
  return(
  <>
    <Navbar transparent />
     <main>
      <section className="pt-20 pb-48">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-center mb-24">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold">
              Privacy Policy
              </h2>
              <p className="text-lg leading-relaxed m-4 text-gray-600">
              Mfactor respects and is committed to protecting personal information. Our Privacy Statement reflects principles and standards on handling personal information.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-12/12 lg:w-12/12 lg:mb-0 mb-12 px-12">
              <div className="">   
              <br/><h5>PERSONAL INFORMATION COLLECTION</h5>
              We collect information that customer and its users has provided to us during subscription and update of user information.
              <br/><br/><h5>INFORMATION USE</h5>
              We use personal iformation for the purpose of making better user experience.
              <br/><br/><h5>INFORMATION ACCESS & INTEGRITY</h5>
              Personal Information is accessed by Mfactor, customer and the authorised therdparties that customer has given consent to.
              <br/><br/><h5>INFORMATION SECURITY</h5>
              We assure you of protecting your personal information against unauthorized use or disclosure.

              <br/><br/><h5>INFORMATION SHARING</h5>
              We do not share your personal information to third parties unless agreed by customer and those third parties promise to give the information at least the equivalent level of protection that we provide.
              </div>
            </div>           
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </>
  );

}