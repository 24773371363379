import React from "react";
import ReactDOM from "react-dom";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import { Redirect,Link } from "react-router-dom";

export default function Terms() {
  return(
  <>
    <Navbar transparent />
     <main>
      <section className="pt-20 pb-48">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-center mb-24">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold">
              Terms & Conditions
              </h2>
              <p className="text-lg leading-relaxed m-4 text-gray-600">
                Our terms and conditions of service
              </p>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-12/12 lg:w-12/12 lg:mb-0 mb-12 px-12">
              <div className="">   
              These Terms and Conditions (“Agreement”) of service constitute a contract between Mfactor and all its users.<br/>
              <br/><h5>DEFINITIONS</h5>
              <ul>
                <li>User - means any user of the Services whom Customer may authorize to enroll to use the Services under the terms of this Agreement.</li>
                <li>Customer - means the customer that has signed up for the Services and agreed to the terms of this Agreement.</li>
                <li>Personal Data - means any Customer Data that is personal data</li>
                <li>Documentation - means guides, instructions, policies and reference materials provided to customer by Mfactor in connection with the Services, including the documentation located at <Link to="/documentation" style={{textDecoration:"underline"}}>Documentations Link</Link>, which Mfactor may amend them from time to time.</li>
                <li>Free Services - means those aspects of the services that are free and do not require payment.</li>
                <li>Fees - means the applicable fees as set on the contract.</li>
                <li>Service Level Agreement - means the description of the availability of the Services as per contract provided to customer</li>
                <li>Software Token - means software security token provided to customer under signed contract of service</li>
              </ul>
              <br/><h5>CUSTOMER OBLIGATIONS</h5>
              <ol>
              <li>a. Customer may only use the Services in accordance with the Documentation and as explicitly set forth in this Agreement. Customer will cooperate with Mfactor in connection with the performance of this Agreement as may be necessary, which may include making available such personnel and information as may be reasonably required to provide the Services or support. Customer is solely responsible for determining whether the Services are sufficient for its purposes, including but not limited to, whether the Services satisfy Customer’s legal and/or regulatory requirements.</li>
              <li>b. Use of the Services may require Users to install Mfactor Mobile App on their mobile devices, which use shall be subject to this Agreement.</li>
              <li>c. Customer acknowledges that the Services will require Users to share with Mfactor certain information which may include personal information regarding Users (such as usernames, passwords, email address and/or phone number) solely for the purposes of providing and improving the Services. Prior to authorizing an individual to become a User, Customer is fully responsible for obtaining the consent of that individual, in accordance with Applicable Law, to the use of his/her information by Mfactor.</li>
              <li>d. Customer will be fully responsible for Users’ compliance with this Agreement and any breach of this Agreement by a User shall be deemed to be a breach by Customer. Mfactor’s relationship is with Customer and not individual Users or third parties using the Services through Customer, and Customer will address all claims raised by its Users, and third parties using the Services through Customer, directly with Mfactor. Customer must ensure that all third parties that utilize the Services through Customer agree (a) to use the Services in full compliance with this Agreement, and (b) to the extent permitted by Applicable Law, to waive any and all claims directly against Mfactor related to the Services.</li>
              <li></li>
              </ol>
              <br/><h5>RESTRICTIONS</h5>
              <ol>
              <li>Customer shall not, and shall not permit any Users nor any third party to: decompile, disassemble, reverse engineer or otherwise attempt to discover the source code, object code or underlying structure, ideas or algorithms of the Services, Software, Any Tokens or any data related to the Services (except to the extent such prohibition is contrary to Applicable Law that cannot be excluded by the agreement of the parties); modify, translate, or create derivative works based on the Services or Software; share, rent, lease, loan, resell, sublicense, distribute, use or otherwise transfer the Services provided.</li>
              <li></li>
              </ol>
              <br/><h5>PAYMENTS FOR SERVICE</h5>
              Customer will pay for service charges and all applicable fees if available in accordance with the payment schedule, terms and agreements undertaken.
              <br/><h5>CONFIDENTIALITY</h5>
              If a Customer uses free services, Mfactor will not charge such customer any fees for use of such free services. Such customer may stop using the free services at any time, and upon that must immediately remove any Mfactor software from all its users devices.
              <br/><h5>INTELLECTUAL PROPERTY RIGHTS</h5>
              Mfactor will retain all Intellectual Property Rights relating to the Services or the Software or any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by Customer or any third party relating to the Services and/or the Software, which are hereby assigned to Mfactor. Customer will not copy, distribute, reproduce or use any of the foregoing except as expressly permitted under this Agreement.
              <br/><h5>DATA PROTECTION</h5>
              For the purposes of the data protection, as between Customer and Mfactor, the parties agree that customer shall at all times be the data controller and Mfactor shall be the data processor with respect to the processing of customer personal data in connection with Customer’s use of the services.<br/>
              Customer may enable integrations between the Services and certain of its Third Party Services. By enabling an Integration between the Services and its Third Party Services, Customer is expressly instructing Mfactor to share the Customer Data necessary to facilitate the Integration. Customer is responsible for providing any and all instructions to the Third Party Service provider about the use and protection of Customer Data. Mfactor and Third Party Service providers are not subprocessors of each other.
              <br/><h5>CONTRACT TERM; TERMINATION</h5>
              The initial term of contract shall be for the term specified in the contract, until the term of contract has expired. Each term shall automatically renew after the initial term and any renewal term for a renewal term equal to the expiring subscription term, unless either party provides to the other at least thirty (30) days prior written notice that it will not renew.<br/>
              Customer may opt to terminate term at any time. If a customer opt to terminate term before its expiration time, 40% of the annual fee shall be paid by customer. 
              
              </div>
            </div>           
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </>
  );

}